const getConfig = (key: string, defaultValue: string): string => {
    return (window as any)[key] || process.env[key] || defaultValue;
};

export default {
    environment: getConfig('NODE_ENV', 'dev'),
    googleClientId: getConfig('VITE_GOOGLE_CLIENT_ID', ''),
    backendURI: getConfig(
        'VITE_BACKEND_URI',
        'https://paula-backend-staging.edukita.com'
    ),
    sentry: {
        dsn: getConfig('VITE_SENTRY_DSN', ''),
        tracesSampleRate: getConfig('VITE_SENTRY_TRACES_SAMPLE_RATE', '0.1'),
    },
    naraBackendURI: getConfig(
        'VITE_NARA_BACKEND_URI',
        'https://nara-staging.edukita.com'
    ),
    btsBackendURI: getConfig(
        'VITE_BTS_BACKEND_URI',
        'https://bts-staging.edukita.com'
    ),
    btsWSBackendURI: getConfig(
        'VITE_BTS_WS_BACKEND_URI',
        'wss://bts-staging.edukita.com'
    ),
    pythagorasURI: getConfig(
        'VITE_PYTHAGORAS_URI',
        'https://math-staging.edukita.com'
    ),
    gtmID: getConfig('VITE_GTM_ID', 'GTM-T52'),
    logger: {
        betterStackSourceToken: getConfig('VITE_BETTERSTACK_SOURCE_TOKEN', ''),
    },
};
